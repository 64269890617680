export const config = {
    firebaseConfig: {
      apiKey: "AIzaSyBHtdb1MqIa3tAEkBL_vZDInP3M53Irl_Y",
      authDomain: "ts-userpage.firebaseapp.com",
      projectId: "ts-userpage",
      storageBucket: "ts-userpage.appspot.com",
      messagingSenderId: "223310301578",
      appId: "1:223310301578:web:89038fe1d91c4822a5a073",
      measurementId: "G-7YM722MV8Q",
    },
  };
  