import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import router from "./App";
import GlobalStyle from "./styles/global.styles";
import { LargeSpinner } from "./components";
import "antd/dist/reset.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <GlobalStyle />
    <Suspense fallback={<LargeSpinner />}>
      <RouterProvider router={router} />
    </Suspense>
  </React.StrictMode>
);
