import { createBrowserRouter } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { AdminPage, FourOhFourPage, LoginPage } from "./pages";
import { config } from "./config/config";
import { AuthRoute } from "./components";

initializeApp(config.firebaseConfig);

const router = createBrowserRouter([
  {
    element: <LoginPage />,
    path: "/",
  },
  {
    element: (
      <AuthRoute>
        <AdminPage />
      </AuthRoute>
    ),
    path: "/admin",
  },
  {
    element: <FourOhFourPage />,
    path: "*",
  },
]);

export default router;
