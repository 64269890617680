import React from "react";
import { BeatLoader } from "react-spinners";
import { Container } from "./LargeSpinner.styles";

interface Props {}

const LargeSpinner: React.FC<Props> = () => {
  return (
    <Container>
      <BeatLoader color="#29d8aa" />
    </Container>
  );
};

export default LargeSpinner;
