import React, { Fragment, useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import LargeSpinner from "../large-spinner";

interface Props {
  children: React.ReactNode;
}

const AuthRoute: React.FC<Props> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const auth = getAuth();
  const navigate = useNavigate();

  const authCheck = onAuthStateChanged(auth, (user) => {
    if (user) {
      setLoading(false);

      if (user.uid === "hlNTCIuhtqcUKXL9UiFH2T9Fjfz1") {
        return;
      } else {
        return navigate("/");
      }
    } else {
      navigate("/");
    }
  });

  useEffect(() => {
    authCheck();
  }, [auth, authCheck]);

  if (loading) return <LargeSpinner />;

  return <Fragment>{children}</Fragment>;
};

export default AuthRoute;
